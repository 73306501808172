'use client';

import Image from 'next/image';
import Link from 'next/link';

import styles from './not-found.module.scss';

export default function NotFound() {
   return (
      <div className={styles.notFoundContainer}>
         <div className={styles.content}>
            <Image src="/images/lost.gif" alt="404" width={398} height={224} />
            <div className={styles.wrapperText}>
               <h2>Page introuvable</h2>
               <p>
                  Nous n’avons pas trouvé la page à laquelle vous souhaitez
                  accéder
               </p>
            </div>

            <Link href="/" className={'m-button m-button--primary'}>
               <span>Retourner à l'accueil</span>
            </Link>
         </div>
      </div>
   );
}
